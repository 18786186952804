// initial state
import axios from 'axios'

const state = {
  added: [],
  feeling: 'good',
  loggedIn: false,
  loginError: null,
  userLogs: [],
  users: [
    {
      name: 'Administrator',
      image: 'romy.png'
    },
    {
      name: 'Mike Nagel',
      image: 'mike.png'
    },
    {
      name: 'Sebastian Witt',
      image: 'sebastian.jpg'
    }
  ],
  loggedInUser: null
}

// getters
const getters = {
  feeling: state => state.feeling,
  loggedIn: state => state.loggedIn,
  loginError: state => state.loginError,
  users: state => state.users,
  userLogs: state => state.userLogs,
  loggedInUser: state => state.loggedInUser,
  userCount: state => {
    return state.users.length
  },
  nameFilteredUsers: state => searchString => {
    return state.users.filter(user => (user.firstName.toLowerCase() + ' ' + user.lastName.toLowerCase()).includes(searchString.toLowerCase()))
  },
  image: state => state.loggedInUser ? 'http://82.165.65.228/rosylernt/img/users/' + state.loggedInUser._id + '.jpg' : null,
  getImage: (state, getters, rootState, rootGetters) => user => {
    // return 'http://82.165.65.228/rosylernt/img/users/' + user._id + '.jpg'
    return rootGetters.API.imgURL + 'users/' + user._id + '.jpg'
  }
}

// actions
const actions = {
  getAll (context) {
    console.log('get users from docker 4.2')
    // return axios.get('http://rosylernt_gui_1:3003/user')
    // return axios.get('http://localhost:3003/user')
    // return axios.get('http://82.165.65.228/rosylernt/api/user')
    return axios.get(context.rootGetters.API.baseURL + 'user')
      .then((response) => context.commit('SET_ALL', response))
      .catch((error) => console.log(error))
  },
  getLogs (context) {
    return axios.get(context.rootGetters.API.baseURL + 'user/log')
      .then((response) => context.commit('SET_LOGS', response))
      .catch((error) => console.log(error))
  },
  login (context, payload) {
    return axios.post(context.rootGetters.API.baseURL + 'user/auth',
      {
        email: payload.email,
        password: payload.password
      }
    ).then(response => {
      console.log('axios login resolved')
      console.log(response.data)
      let payload = {
        user: {
          userName: response.data.userName,
          userId: response.data.userId,
          image: response.data.image,
          level: response.data.level,
        },
        token: response.data.token
      }
      if (!response.data.success) {
        console.log('login error')
        context.commit('SET_LOGIN_ERROR', response.data.message)
      } else {
        context.commit('LOGIN', payload)
        context.commit('SET_TOKEN', payload.token, {root: true})
      }
      return response
    }).catch(error => {
      console.log(error)
      this.errored = true
    })
  },
  update (context, payload) {
    console.log('update user')
    return axios.patch(context.rootGetters.API.baseURL + 'user/' + payload._id, payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  updatePassword (context, payload) {
    console.log('updatePassword')
    return axios.patch(context.rootGetters.API.baseURL + 'user/pwd/' + payload._id, payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  uploadImage (context, payload) {
    console.log('uploadImage')
    return axios.post(
      context.rootGetters.API.baseURL + 'user/upload',
      payload,
      context.rootGetters.axiosConfig
    ).then(response => {
      // success callback
      console.log(response)
      console.log('UPLOADED')
    }).catch(error => {
      console.log(error)
    })
  },
  createLog (context, payload) {
    console.log('create log')
    console.log(payload)
    return axios.put(context.rootGetters.API.baseURL + 'user/log', payload)
      .then((response) => context.dispatch('getLogs', response))
      .catch((error) => console.log(error))
  }
}

// mutations
const mutations = {
  LOGIN (state, payload) {
    state.loggedIn = true
    state.loggedInUser = payload.user
  },
  SET_LOGIN_ERROR (state, data) {
    state.loginError = data
  },
  logout (state) {
    state.loggedIn = false
    state.loggedInUser = null
    console.log(state)
  },
  SET_ALL (state, payload) {
    console.log('SET_ALL')
    console.log(payload)
    state.users = payload.data
    console.log(state.users)
  },
  SET_LOGS (state, payload) {
    console.log('SET_LOGS')
    console.log(payload)
    state.userLogs = payload.data
    console.log(state.userLogs)
  },
  UPDATE_LOGGED_IN_USER (state, payload) {
    console.log('UPDATE_LOGGED_IN_USER')
    state.loggedInUser = payload.user
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
