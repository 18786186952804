<template>
  <div class="page users" v-if="users">
    <div class="header">
      <h1>User</h1>
    </div>
    <div class="content">      
      <perfect-scrollbar>
        <div class="list-item" v-for="user in users" v-on:click="goUser(user._id)" :key="user._id"> 
        <div class="list-item-icon"><img :src="API.imagePath + 'users/' + user.image"></div>
          <div class="list-item-text"> 
            <div class="list-item-text-title">{{user.userName }}</div>
            <div class="list-item-text-sub"> {{user.email }}</div>          
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Users',
  data () {
    return {
      test: []
    }
  },
  methods: {
    goUser: function (id) {
      if (id === this.loggedInUser.userId || this.loggedInUser.level === 'ADMIN') {
        this.$router.push({name: 'User', params: { id: id}})
      }
    },
    getUsers: function () {
      this.$store.dispatch('users/getAll')
    }
  },
  computed: {  
    ...mapGetters({
      API: 'API',
      loggedInUser: 'users/loggedInUser',
      users: 'users/users',
    })
  },
  created: function () {
    this.getUsers()
  }
}
</script>

