<template>
  <div class="k-time-picker">
    <input type="number" v-model="time.HH"/>
    <span>:</span>
    <input type="number" v-model="time.mm"/>
  </div>
</template>

<style>
  .k-time-picker { display: flex; align-items: center; }
  .k-time-picker input { width: 80px; }
</style>

<script>

export default {
  name: 'k-time-picker',
  props: {
    time: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isOpen: false
    }
  }
}
</script>
