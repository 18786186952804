import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Moment from 'vue-moment'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Datepicker from 'vuejs-datepicker'
import Timepicker from 'vue2-timepicker'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"

import AutoCompleteInput from '@/components/AutoCompleteInput'
import ListPicker from '@/components/ListPicker'
import KTimePicker from '@/components/KTimePicker'
import KTimePickerMoment from '@/components/KTimePickerMoment'
import ModalLog from '@/components/ModalLog'

Vue.config.productionTip = false

const moment = require('moment')
require('moment/locale/de')
moment.locale('de')

Vue.use(Moment, {moment}) 
Vue.use(PerfectScrollbar)

Vue.component('autocomplete-input', AutoCompleteInput)
Vue.component('list-picker', ListPicker)
Vue.component('k-time-picker', KTimePicker)
Vue.component('k-time-picker-moment', KTimePickerMoment)
Vue.component('dropzone', vue2Dropzone)
Vue.component('datepicker', Datepicker)
Vue.component('timepicker', Timepicker)
Vue.component('modal-log', ModalLog)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
