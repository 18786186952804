// initial state
import axios from 'axios'
import async from 'async'

const state = {
  projects: [],
  projectStates: [
    {
      name: 'OFFERED',
      displayName: 'angeboten'
    },
    {
      name: 'WIP',
      displayName: 'in Arbeit'
    },
    {
      name: 'DONE',
      displayName: 'fertig'
    },
    {
      name: 'CANCELLED',
      displayName: 'abgebrochen'
    }
  ]
}

// getters
const getters = {
  projects: state => state.projects,
  projectStates: state => state.projectStates,
  getStatusName: state => status => state.projectStates.find(o => o.name === status).displayName,
  activeProjects: state => state.projects.filter(o => o.status === 'OFFERED' || o.status === 'WIP'),
  inactiveProjects: state => state.projects.filter(o => o.status === 'DONE' || o.status === 'CANCELLED'),
  activeProjectsByCustomer: state => customer => state.projects.filter(o => o.customer).filter(o => o.customer._id === customer._id).filter(o => o.status === 'WIP' || o.status === 'OFFERED'),
  inactiveProjectsByCustomer: state => customer => state.projects.filter(o => o.customer).filter(o => o.customer._id === customer._id).filter(o => o.status === 'DONE' || o.status === 'CANCELLED'),
}

// actions
const actions = {
  getAll (context) {
    console.log('GET Projects')
    console.log(context.rootGetters.axiosConfig)
    return axios.get(context.rootGetters.API.baseURL + 'projects', context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_ALL', response))
      .catch((error) => console.log(error))
  },
  update (context, payload) {
    console.log('update')
    return axios.patch(context.rootGetters.API.baseURL + 'projects/' + payload._id, payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  uploadImage (context, payload) {
    console.log('uploadImage')
    return axios.post(
      context.rootGetters.API.baseURL + 'projects/upload',
      payload,
      context.getters.axiosConfig
    ).then(response => {
      // success callback
      console.log(response)
      console.log('UPLOADED')
    }).catch(error => {
      console.log(error)
    })
  },
  create (context, payload) {
    console.log('create project')
    console.log(payload)
    return axios.put(context.rootGetters.API.baseURL + 'projects', payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  seed (context, payload) {
    console.log('seed projects')
    // console.log(payload)
    let all = JSON.parse(payload)[2].data
    console.log(all)
    return axios.put(context.rootGetters.API.baseURL + 'projects/bulk', all, context.rootGetters.axiosConfig)
      .then((response) => context.dispatch('getAll', response))
      .catch((error) => console.log(error))
  },
  setCustomersFromLegacy (context) {
    console.log('setCustomersFromLegacy')
    async.each(context.getters.projects, function(item, callback) {

      console.log('Processing item:')
      console.log(item)

      let customer = context.rootGetters['customers/customers'].find(o => o.legacyId === item.legacyCustomerId)

      axios.patch(context.rootGetters.API.baseURL + 'projects/' + item._id, 
      {
        customer: customer._id
      }, 
      context.rootGetters.axiosConfig)
      .then((response) => {
        console.log(response)
        callback()
      })
      .catch((error) => console.log(error))
  }, function (err) {
      if( err ) {
        console.log('An item failed to process')
      } else {
        console.log('All item have been processed successfully')
      }
  })
  }
}

// mutations
const mutations = {
  SET_ALL (state, payload) {
    console.log('SET_ALL')
    console.log(payload)
    state.projects = payload.data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
