import Vue from 'vue'
import Router from 'vue-router'

import Projects from '@/components/Projects'
import Project from '@/components/Project'
import Users from '@/components/Users'
import User from '@/components/User'
import Customers from '@/components/Customers'
import Customer from '@/components/Customer'
import Login from '@/components/Login'
import Work from '@/components/Work'
import Seeder from '@/components/Seeder'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Hello',
      component: Login
    },
    {
      path: '/projects/',
      name: 'ProjectsDefault',
      component: Projects
    },
    {
      path: '/work/',
      name: 'Work',
      component: Work
    },
    {
      path: '/projects/:state',
      name: 'Projects',
      component: Projects
    },
    {
      path: '/project/:id',
      name: 'Project',
      component: Project
    },
    {
      path: '/users/',
      name: 'Users',
      component: Users
    },
    {
      path: '/user/:id',
      name: 'User',
      component: User
    },
    {
      path: '/customers/',
      name: 'Customers',
      component: Customers
    },
    {
      path: '/customer/:id',
      name: 'Customer',
      component: Customer
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/seed',
      name: 'Seeder',
      component: Seeder
    }
  ]
})
