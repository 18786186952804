<template>
  <div class="k-time-picker">
    <input type="number" v-model="hours" @change="onChange"/>
    <span>:</span>
    <input type="number" v-model="minutes" @change="onChange"/>
  </div>
</template>

<style>
  .k-time-picker { display: flex; align-items: center; }
  .k-time-picker input { width: 80px; }
</style>

<script>
import moment from 'moment'

export default {
  name: 'k-time-picker',
  props: {
    time: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      hours: moment(this.time).format('HH'),
      minutes: moment(this.time).format('mm')
    }
  },
  methods: {
    onChange: function () {
      // this.time.minutes(this.minutes)
      var synctime = moment(this.time).hours(this.hours).minutes(this.minutes).format('YYYY-MM-DD HH:mm:ss')
      this.$emit('update:time', synctime)
      this.$emit('change')
    }
  }
}
</script>
