<template>
  <div class="page customers">
    <div class="header shadow">
      <h1>Kunden</h1>
    </div>
    <div class="content"> 
      <perfect-scrollbar>
        <div class="list">
          <div class="list-item" v-for="customer in activeCustomers" v-on:click="goCustomer(customer._id)" :key="customer.id">         
            <div class="list-item-icon"><img :src="API.imagePath + 'customers/' + customer.image" v-if="customer.image"></div>
            <!--div class="list-item-icon"><img :src="'https://track.koordinauten.de/api_upload/customerimg/' + customer.image"></div-->
            <div class="list-item-text"> 
              <div class="list-item-text-title"> {{customer.name }}</div>     
            </div>
          </div>
          
          <div class="list-headline">Inaktive Kunden</div>
          <div class="list-item inactive" v-for="customer in inactiveCustomers" v-on:click="goCustomer(customer._id)" :key="customer._id">         
            <div class="list-item-icon"><img :src="API.imagePath + 'customers/' + customer.image" v-if="customer.image"></div>
            <div class="list-item-text"> 
              <div class="list-item-text-title"> {{customer.name }}</div>     
            </div>
          </div>
        </div>
      </perfect-scrollbar>
      
    </div>
    
    <div class="fab" @click="createMode = true">+</div>
  
    <div class="modal" v-if="createMode">
      <div class="popup">
        <div class="popup-title">Neuer Kunde</div> 
        <div class="popup-content">     
          <input type="text" placeholder="Name" v-model="createName"/> 
        </div>       
        <div class="popup-buttons">
          <div class="popup-button negative" @click="createMode = false">Abbrechen</div> 
          <div class="popup-button" @click="createCustomer()">OK</div>           
        </div>       
      </div>    
    </div>  
    
  </div>
  
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Customers',
  data () {
    return {
      createMode: false,
      createName: ''
    }
  },
  methods: {
    goCustomer: function (id) {
      this.$router.push('customer/' + id)
    },
    getCustomers: function () {
      this.$store.dispatch('customers/getAll')
    },
    createCustomer: function () {
      let payload = {
        name: this.createName
      }
      this.$store.dispatch('customers/create', payload).then((res) => {
        let createdId = res.data
        this.$store.dispatch('customers/getAll').then((res) => {
          console.log(res)
          this.$router.push({ name: 'Customer', params: { id: createdId } })
        })
      })
    }
  },
  computed: {  
    ...mapGetters({
      API: 'API',
      users: 'users/users',
      activeCustomers: 'customers/activeCustomers',
      inactiveCustomers: 'customers/inactiveCustomers',
    })
  },
  mounted: function () {
    this.getCustomers()
  }
}
</script>

