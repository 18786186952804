<template>
  <div class="list-picker">
    <input
      :placeholder="!!placeholder ? placeholder : 'Search...'"
      v-model="keyword"
      @click="onClick($event.target.value)"
      @keyup.esc="isOpen = false" 
      :disabled="disabled"
    >
  
    <ul class="options-list" v-show="isOpen">
      <li v-for="(option, index) in options" :class="{'highlighted': option.title === keyword}" :key="'oli--' + index"
        @mouseenter="highlightedPosition = index"
        @mousedown="select">
        <slot name="item"
          :title="option.title"
          :description="option.description"
          :thumbnail="option.thumbnail"
        />
      </li>
    </ul>
    <div class="options-list-btn-close" v-show="isOpen" v-on:click="isOpen = false"></div>
  </div>
</template>

<style>
  .list-picker { display: inline-block; }
  .list-picker input { margin: 0; }
  .list-picker .options-list { background: #fff; padding: 0; list-style: none; margin: 0; border-top: 1px solid #ccc; position: fixed; top: 0; left: 0; z-index: 2000; width: 100%; height: 100vh; overflow-y: scroll; }
  .list-picker .options-list li { padding: 10px; border-bottom: 1px solid #ccc; }
  .list-picker .options-list li.highlighted { background-color: #000; color: #fff; }
  .list-picker .options-list-btn-close { position: fixed; top: 10px; right: 10px; z-index: 2010; background-color: #fff; width: 40px; height: 40px; border-radius: 50%; box-shadow: 2px 2px 6px rgba(0,0,0,0.2); }
</style>

<script>

export default {
  name: 'list-picker',
  props: {
    options: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    initTitle: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      isOpen: false,
      highlightedPosition: 0,
      keyword: ''
    }
  },
  methods: {
    onClick (value) {
      this.isOpen = !!value
      this.highlightedPosition = 0
    },
    select () {
      const selectedOption = this.options[this.highlightedPosition]
      this.keyword = selectedOption.title
      this.isOpen = false
      this.$emit('select', selectedOption)
    }
  },
  watch: {
    initTitle: function (val) {
      if (val) {
        this.keyword = val
      }
    }
  }
}
</script>
