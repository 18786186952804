<template>
  <div class="autocomplete-input">
    <input
      class="input is-large"
      :placeholder="!!placeholder ? placeholder : 'Search...'"
      v-model="keyword"
      @input="onInput($event.target.value)"
      @keyup.esc="isOpen = false"
      @blur="isOpen = false"
      @keydown.down="moveDown"
      @keydown.up="moveUp"
      @keydown.enter="select"      
    >
    <i class="fa fa-angle-down"></i>
  
    <ul class="options-list" v-show="isOpen">
      <li v-for="(option, index) in filteredOptions" :class="{'highlighted': index === highlightedPosition}" :key="'oli-' + index"
        @mouseenter="highlightedPosition = index"
        @mousedown="select">
        <slot name="item"
          :title="option.title"
          :description="option.description"
          :thumbnail="option.thumbnail"
        />
      </li>
    </ul>
  </div>
</template>

<style>
  .autocomplete-input { display: inline-block; }
  .autocomplete-input input { margin: 0; }
  .autocomplete-input .options-list { background: #fff; padding: 0; list-style: none; margin: 0; border-top: 1px solid #ccc; }
  .autocomplete-input .options-list li { padding: 10px; border-bottom: 1px solid #ccc; }
  .autocomplete-input .options-list li.highlighted { background-color: #f2f2f2; }
</style>

<script>

export default {
  name: 'autocomplete-input',
  props: {
    options: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    initTitle: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      isOpen: false,
      highlightedPosition: 0,
      keyword: ''
    }
  },
  computed: {
    filteredOptions () {
      const re = new RegExp(this.keyword, 'i')
      return this.options.filter(o => o.title.match(re))
    }
  },
  methods: {
    onInput (value) {
      this.isOpen = !!value
      this.highlightedPosition = 0
    },
    moveDown () {
      if (!this.isOpen) {
        return
      }
      this.highlightedPosition = (this.highlightedPosition + 1) % this.filteredOptions.length
    },
    moveUp () {
      if (!this.isOpen) {
        return
      }
      this.highlightedPosition = this.highlightedPosition - 1 < 0
        ? this.filteredOptions.length - 1
        : this.highlightedPosition - 1
    },
    select () {
      const selectedOption = this.filteredOptions[this.highlightedPosition]
      this.keyword = selectedOption.title
      this.isOpen = false
      this.$emit('select', selectedOption)
    }
  },
  watch: {
    initTitle: function (val) {
      if (val) {
        this.keyword = val
      }
    }
  }
}
</script>
