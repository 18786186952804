<template>
  <div class="page customer" v-if="customer">
    <div class="header">
      <h1>{{customer.name}}</h1>
    </div>
    <div class="content">
      <perfect-scrollbar>
      
        <div class="user-head">
            <button v-if="!customer.image" @click="toggleUpload()" >Bild hinzufügen</button>
          <div class="user-head-image avatar-outer" :class="{ empty: customer.image === '' }" @click="toggleUpload()">
            <img class="avatar" :src="API.imagePath + 'customers/' + customer.image" v-if="customer.image">
          </div>
          <div class="user-head-text">
            <div class="user-head-text-col">
              <input type="text" v-model="customer.name" @blur="updateCustomer" @keyup.enter="updateCustomer"/>      
            </div>
          </div>
        </div>
        
        <div class="user-dropzone-outer" v-if="uploadActive">                       
          <dropzone ref="userDropzone" id="dropzone" :options="dropzoneOptions" @vdropzone-success="dropzoneSuccess"/>
        </div>     
        
        <label class="checkbox-container">Für Projektauswahl anzeigen
          <input type="checkbox" v-model="customer.isActive" v-on:change="updateCustomer">
          <span class="checkmark"></span>
        </label>
        
        <div class="list-title">Aktive Projekte</div>
        <div class="list-item" v-for="project in activeProjectsByCustomer(this.customer)" v-on:click="goProject(project._id)" :key="project._id">         
          <div class="list-item-icon"><img :src="API.imagePath + 'projects/' + project.image"></div>
          <div class="list-item-text"> 
            <div class="list-item-text-title"> {{ project.number }} {{ project.name }}</div>
            <div class="list-item-text-sub">{{ project.status }}</div>          
          </div>
        </div>
      
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'Customer',
  data () {
    return {
      customer: {},
      uploadActive: false,
      dropzoneOptions: {
        url: 'https://hu.koordinauten.de/api_upload/upload.php',
        thumbnailWidth: 150,
        paramName: 'image',
        resizeWidth: 1024,
        maxFilesize: 3.75,
        params: {
          id: 0
        }
      }
    }
  },
  methods: {
    getCustomer: function () {
      console.log('getCustomer')
      this.customer = this.customers.find(o => o._id === this.$route.params.id)
      console.log(this.customer)
    },
    goProject: function (id) {
      this.$router.push('../project/' + id)
    },
    timeString: function (minutes) {
      return parseFloat(minutes / 60).toFixed(2).replace('.', ',')
    },
    updateCustomer: function () {
      this.$store.commit('SET_BUSY', true)
      this.$store.dispatch('customers/update', this.customer).then(() => {
        this.$store.commit('SET_BUSY', false)
      })
    },
    toggleUpload () {
      console.log('toggleUpload')
      this.uploadActive = !this.uploadActive
    },
    getLogs: function () {
      console.log(this.$route.params.id + '/' + this.selectedYear + '/' + this.selectedMonth.index)
      this.$http.get('http://localhost:3000/worklogsByCustomer/' + this.$route.params.id + '/' + this.selectedYear + '/' + this.selectedMonth.index).then(response => {
        // success callback
        this.logs = response.body
        console.log(this.logs)
      }, response => {
        console.log(response)
        // error callback
      })
    },
    projectStateName: function (project) {
      return _.find(this.projectStates, function (o) { return o.id === project.state }).title
    },
    dropzoneSuccess: function (file, response) {
      console.log(response)
      this.uploadActive = false
      this.customer.image = response
      this.updateCustomer()
    }
  },
  computed: {
    totalTime: function () {
      var time = 0
      _.forEach(this.logs, function (value) {
        time += value.minutes
      })
      return this.timeString(time)
    },
    ...mapGetters({
      API: 'API',
      axiosConfig: 'axiosConfig',
      users: 'users/users',
      customers: 'customers/customers',
      projects: 'projects/projects',
      projectsByCustomer: 'projects/projectsByCustomer',
      activeProjectsByCustomer: 'projects/activeProjectsByCustomer',
      inactiveProjectsByCustomer: 'projects/inactiveProjectsByCustomer',
    })
  },
  mounted () {
    this.getCustomer()
  },
  watch: {
    '$route.params.id': function () {
      this.getCustomer()
    },
    customer: function (val) {
      this.dropzoneOptions.params.id = val._id
      this.dropzoneOptions.url = this.API.baseURL + 'customers/upload'
      this.dropzoneOptions.headers = this.axiosConfig.headers
    },
    selectedMonth: function () {
      this.getLogs()
    },
    selectedYear: function () {
      this.getLogs()
    }
  }
}
</script>

