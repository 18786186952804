// initial state
import axios from 'axios'
import async from 'async'

const state = {
  worklogs: [],
  editorVisible: false,
  creating: false,
}

// getters
const getters = {
  worklogs: state => state.worklogs,
  editorVisible: state => state.editorVisible,
  creating: state => state.creating,
}

// actions
const actions = {
  getAll (context) {
    return axios.get(context.rootGetters.API.baseURL + 'worklogs', context.rootGetters.axiosConfig)
      .then((response) => {
        context.commit('SET_ALL', response)
        return response
      })
      .catch((error) => console.log(error))
  },
  getByProject (context, payload) {
    console.log('getByProject')
    return axios.get(context.rootGetters.API.baseURL + 'worklogs/project/' + payload, context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_ALL', response))
      .catch((error) => console.log(error))
  },
  getCsvByProject (context, payload) {
    console.log('getCsvByProject')
    let config = {}
    config.header = context.rootGetters.axiosConfig.headers
    config.responseType = 'blob'
    console.log(context.rootGetters.axiosConfig)
    return axios.get(context.rootGetters.API.baseURL + 'worklogs/project/' + payload + '/csv', context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  getByUser (context, payload) {
    console.log('getByUser')
    return axios.get(context.rootGetters.API.baseURL + 'worklogs/user/' + payload, context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_ALL', response))
      .catch((error) => console.log(error))
  },
  update (context, payload) {
    console.log('update')
    return axios.patch(context.rootGetters.API.baseURL + 'worklogs/' + payload._id, payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  create (context, payload) {
    console.log('create log')
    console.log(payload)
    return axios.put(context.rootGetters.API.baseURL + 'worklogs', payload, context.rootGetters.axiosConfig)
      .then((response) => context.dispatch('getAll', response))
      .catch((error) => console.log(error))
  },
  delete (context, payload) {
    console.log('delete log')
    console.log(payload)
    return axios.delete(context.rootGetters.API.baseURL + 'worklogs/' + payload._id, context.rootGetters.axiosConfig)
      .then((response) => context.dispatch('getAll', response))
      .catch((error) => console.log(error))
  },
  seed (context, payload) {
    console.log('seed logs')
    let all = JSON.parse(payload)[2].data
    console.log(all)
    return axios.put(context.rootGetters.API.baseURL + 'worklogs/bulk', all, context.rootGetters.axiosConfig)
      .then((response) => context.dispatch('getAll', response))
      .catch((error) => console.log(error))
  },
  setProjectFromLegacy (context) {
    console.log('setProjectFromLegacy')
    async.each(context.getters.worklogs, function(item, callback) {

      console.log('Processing item:')
      console.log(item)
      let project = context.rootGetters['projects/projects'].find(o => o.legacyId === item.legacyProjectId)

      axios.patch(context.rootGetters.API.baseURL + 'worklogs/' + item._id, 
      {
        project: project._id
      },
      context.rootGetters.axiosConfig)
      .then((response) => {
        console.log(response)
        callback()
      })
      .catch((error) => console.log(error))
  }, function (err) {
      if( err ) {
        console.log('An item failed to process')
      } else {
        console.log('All item have been processed successfully')
      }
  })
  },
  setUserFromLegacy (context) {
    console.log('setUserFromLegacy')
    async.each(context.getters.worklogs, function(item, callback) {

      console.log('Processing item:')
      console.log(item)
      let user = context.rootGetters['users/users'].find(o => o.legacyId === item.legacyUserId)

      axios.patch(context.rootGetters.API.baseURL + 'worklogs/' + item._id, 
      {
        user: user._id
      },
      context.rootGetters.axiosConfig)
      .then((response) => {
        console.log(response)
        callback()
      })
      .catch((error) => console.log(error))
  }, function (err) {
      if( err ) {
        console.log('An item failed to process')
      } else {
        console.log('All item have been processed successfully')
      }
  })
  }
}

// mutations
const mutations = {
  SET_ALL (state, payload) {
    console.log('SET_ALL')
    console.log(payload)
    state.worklogs = payload.data
  },
  SET_EDITOR_VISIBLE (state, data) {
    state.editorVisible = data
  },
  SET_CREATING (state, data) {
    state.creating = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
