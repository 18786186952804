<template>
  <div class="seeder">
    <div class="header shadow">
      <h1>Seeder</h1>
    </div>
    <div class="content">
      <textarea v-model="jsonString"/>
      <button @click="onSubmit()">POST</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      jsonString: ''
    }
  },
  methods: {
    onSubmit () {
      console.log('onSubmit')
      // this.$store.dispatch('customers/seed', this.jsonString)
      // this.$store.dispatch('projects/seed', this.jsonString)
      // this.$store.dispatch('projects/setCustomersFromLegacy')
      // this.$store.dispatch('worklogs/seed', this.jsonString)
      this.$store.dispatch('worklogs/getAll').then(() => {
        // this.$store.dispatch('worklogs/setProjectFromLegacy')
        this.$store.dispatch('worklogs/setUserFromLegacy')
      })
    }
  },
  computed: {  
    ...mapGetters({
      users: 'users/users',
      customers: 'customers/customers',
      projects: 'projects/projects',
    })
  },
  created: function () {
    // s
  }
}
</script>

