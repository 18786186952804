<template>
  <div class="modal">
      <div class="popup">
        <div class="popup-title" v-if="creating">Zeit erfassen</div> 
        <div class="popup-title" v-if="!creating">Log bearbeiten</div> 
        <div class="popup-content" v-if="log">
          <div class="input-line">
            <label>Datum</label>
            <datepicker v-model="log.workStart" format="d. MMM yyyy" language="de"></datepicker>
          </div>
          <!--div class="input-line">
            <label>Start</label>
            <timepicker v-model="log.workStart" :minute-interval="5"></timepicker>
          </div> <div class="input-line">
            <label>Ende</label>
            <timepicker v-model="log.workEnd" :minute-interval="10"></timepicker>
          </div-->
          <div class="input-line">
            <label>Kommentar</label>
            <input type="text" class="full-width" v-model="log.comment"/>
          </div> 
          <div class="input-line">
            <label>Stunden</label>
            <input type="number" class="full-width" v-model="editHours"/>
          </div> 
        </div>       
        <div class="popup-buttons">
          <div class="popup-button delete" @click="deleteClicked">Löschen</div> 
          <div class="popup-button negative" @click="negativeClicked">Abbrechen</div> 
          <div class="popup-button" @click="positiveClicked">OK</div>           
        </div>       
      </div>    
    </div>    
</template>


<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'modal-log',
  props: {
    log: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      logComment: '',
      editHours: 0,
      logDate: moment().format(),
      logStartTime: {
        HH: moment().subtract({ hours: 1 }).format('HH'),
        mm: moment().format('mm')
      },
      logEndTime: {
        HH: moment().format('HH'),
        mm: moment().format('mm')
      }
    }
  },
  methods: {
    negativeClicked: function () {
      this.$store.commit('worklogs/SET_EDITOR_VISIBLE', false)
      this.$store.commit('worklogs/SET_CREATING', false)
    },
    positiveClicked: function () {
      if (this.creating) {
        this.createLog()
      } else {
        this.updateLog()
      }
    },
    deleteClicked: function () {
     this.$store.dispatch('worklogs/delete', this.log).then(() => {
        this.$store.dispatch('worklogs/getByProject', this.$route.params.id).then(() => {
          this.$store.commit('SET_BUSY', false)
          this.$store.commit('worklogs/SET_EDITOR_VISIBLE', false)
        })
      })
    },
    updateLog: function () {
      this.$store.commit('SET_BUSY', true)
      this.log.minutes = this.editHours * 60
      this.$store.dispatch('worklogs/update', this.log).then(() => {
        this.$store.dispatch('worklogs/getByProject', this.$route.params.id).then(() => {
          this.$store.commit('SET_BUSY', false)
          this.$store.commit('worklogs/SET_EDITOR_VISIBLE', false)
        })
      })
    },
    createLog: function () {
      this.$store.commit('SET_BUSY', true)
      this.log.minutes = this.editHours * 60
      this.$store.dispatch('worklogs/create', this.log).then(() => {
        this.$store.dispatch('worklogs/getByProject', this.$route.params.id).then(() => {
          this.$store.commit('SET_BUSY', false)
          this.$store.commit('worklogs/SET_EDITOR_VISIBLE', false)
          this.$store.commit('worklogs/SET_CREATING', false)
        })
      })
    }
  },
  computed: {  
    ...mapGetters({
      users: 'users/users',
      loggedInUser: 'users/loggedInUser',
      creating: 'worklogs/creating',
    })
  },
  mounted: function () {
    console.log('log modal mounted')
    console.log(this.log)
    if (this.log === null) {
      this.log = {
        workStart: new Date(),
        minutes: 30,
        comment: '',
        project: this.$route.params.id,
        user: this.loggedInUser.userId
      }
    }
    this.editHours = Number.parseFloat(this.log.minutes / 60).toFixed(2)
  }
}
</script>