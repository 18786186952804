<template>
  <div class="login">
    <div class="header">
      <h1>Login</h1>
    </div>
    <div class="page content">      
      <div class="form-container">
        <form v-on:submit.prevent="login">
          <div class="input-line full-width">
            <label>USR</label>
            <input type="text" placeholder="E-Mail" class="full-width" v-model="email"/>        
          </div>
          <div class="input-line full-width">
            <label>PWD</label>
            <input type="password" placeholder="Passwort" class="full-width" v-model="password"/>
          </div> 
          <div class="input-line full-width">
            <input type="submit" value="Loslegen">
          </div> 
        </form>
      </div>
      <p>{{loginError}}</p>
      <p>v 2021-03-03 1723</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    login () {
      console.log('login:')
      console.log(this.email)
      let payload = {
        email: this.email,
        password: this.password,
      }
      this.$store.dispatch('users/login', payload).then(() => {
        if (!this.loginError) {
          this.$store.dispatch('projects/getAll')
          this.$store.dispatch('users/getAll')
          this.$store.dispatch('customers/getAll')
          this.$router.push({ name: 'Projects'})
        }
      })
    }
  },
  computed: {  
    ...mapGetters({
      API: 'API',
      loginError: 'users/loginError',
    })
  },
  created: function () {
    // s
  }
}
</script>

