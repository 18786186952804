// initial state
import axios from 'axios'

const state = {
  customers: []
}

// getters
const getters = {
  customers: state => state.customers,
  activeCustomers: state => state.customers.filter(o => o.isActive),
  inactiveCustomers: state => state.customers.filter(o => !o.isActive)
}

// actions
const actions = {
  getAll (context) {
    return axios.get(context.rootGetters.API.baseURL + 'customers', context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_ALL', response))
      .catch((error) => console.log(error))
  },
  update (context, payload) {
    console.log('update')
    return axios.patch(context.rootGetters.API.baseURL + 'customers/' + payload._id, payload, context.rootGetters.axiosConfig)
      .then((response) => {
        context.dispatch('getAll', response)
      })
      .catch((error) => console.log(error))
  },
  uploadImage (context, payload) {
    console.log('uploadImage')
    return axios.post(
      context.rootGetters.API.baseURL + 'customers/upload',
      payload,
      context.getters.axiosConfig
    ).then(response => {
      // success callback
      console.log(response)
      console.log('UPLOADED')
    }).catch(error => {
      console.log(error)
    })
  },
  create (context, payload) {
    console.log('create customer')
    console.log(payload)
    return axios.put(context.rootGetters.API.baseURL + 'customers', payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  seed (context, payload) {
    console.log('seed customers')
    // console.log(payload)
    let all = JSON.parse(payload)[2].data
    console.log(all)
    return axios.put(context.rootGetters.API.baseURL + 'customers/bulk', all, context.rootGetters.axiosConfig)
      .then((response) => context.dispatch('getAll', response))
      .catch((error) => console.log(error))
  }
}

// mutations
const mutations = {
  SET_ALL (state, payload) {
    console.log('SET_ALL')
    console.log(payload)
    state.customers = payload.data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
