<template>
  <div id="app" :style="appStyle()">
    <div class="header-logo" @click="toggleNav()"></div>  
    
    <a class="header-user" v-if="loggedInUser" @click="goUser()">
      <img class="avatar small" :src="API.imagePath + 'users/' + loggedInUser.image">
      <div class="header-user-name">{{loggedInUser.userName}}</div>
    </a>
    <transition name="fade">     
      <router-view v-if="loggedInUser || this.$route.path === '/login'"/>
    </transition>
    <div class="nav" :class="{active: menuActive}" v-if="loggedInUser">
      <div class="nav-bg" @click="menuActive = false"></div>    
      <div class="nav-inner" >
        <!--a href="./#/work">Arbeiten</a-->
        <a href="./#/projects">Projekte</a>
        <a href="./#/customers">Kunden</a>
        <a href="./#/users" v-if="loggedInUser.level === 'ADMIN'">User</a>
        <!--a href="./#/seed">Seed</a-->
        <!--a @click="logout">Abmelden</a-->
      </div>
    </div>
    <transition name="busy-fade">     
      <div class="busy-modal" v-if="busy"></div>
    </transition>     
    
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'app',
  data: function () {
    return {
      menuActive: false,
    }
  },
  methods: {
    logout: function () {
      this.$http.post(
        'http://raumstation-endstation.de/stonethrone/api/wrog.php',
        {
          id: 8
        }
      ).then(response => {
        // success callback
        console.log(response)
      }, response => {
        // error callback
        console.log(response)
      })

      window.localStorage.removeItem('usernick')
      window.localStorage.removeItem('username')
      window.localStorage.removeItem('userimage')
      window.localStorage.removeItem('userid')

      this.$router.push({name: 'Login'})
    },
    goProjects: function () {
      this.$router.push('projects/')
    },
    goUser: function () {
      this.$router.push({name: 'User', params: {id: this.loggedInUser.userId}})
    },
    toggleNav () {
      this.menuActive = !this.menuActive
    },
    appStyle () {
      // document.querySelector('body').style.height = window.innerHeight + 'px'
      let style = {}
      style.height = window.innerHeight + 'px'
      return style
    },
    onResize () {
      console.log('onResize')
      this.$forceUpdate()
    },
    onOrientationChange () {
      console.log('onOrientationChange')
      setTimeout(()=> {
        this.$forceUpdate()
      }, 100)
    }
  },
  computed: {  
    ...mapGetters({
      API: 'API',
      busy: 'busy',
      users: 'users/users',
      loggedInUser: 'users/loggedInUser',
    })
  },
  watch: {
    '$route': function () {
      this.menuActive = false
    }
  },
  beforeMount() {
    window.addEventListener("orientationchange", this.onOrientationChange)
    window.addEventListener("resize", this.onResize)
  },
  mounted: function () {
    if (!this.loggedInUser) {
      this.$router.push({name: 'Login'})
    }
    console.log('ENV:')
    console.log(process.env)
  }
}
</script>

<style>
@import "./assets/css/k-tracker.css"
</style>
