<template>
  <div class="page projects">
    <div class="header">
      <h1>Projekte</h1>
    </div>
    
    <div class="header-nav">
      <a href="./#/projects/inactive" :class="{ active: this.$route.params.state === 'inactive' }">Inaktive</a> 
      <a href="./#/projects/myactive" :class="{ active: this.$route.params.state === 'myactive' || !this.$route.params.state}">Meine aktiven</a> 
      <a href="./#/projects/allactive" :class="{ active: this.$route.params.state === 'allactive'}">Alle aktiven</a> 
    </div>
    
    <div class="content sub-nav">
      <perfect-scrollbar>
        <div class="list-item" v-for="project in filteredProjects" v-on:click="goProject(project._id)" :key="project._id">         
          <div class="list-item-icon"><img :src="API.imagePath + 'projects/' + project.image" @error="onImageError" v-if="project.image"></div>
          <div class="list-item-text"> 
            <div class="list-item-text-title"> {{project.number }} {{project.name }}</div>
            <div class="list-item-text-sub" v-if="project.customer">{{project.customer.name }} <span class="light">| {{ getStatusName(project.status) }}</span></div>          
          </div>
        </div>
      </perfect-scrollbar>
    </div>
    
    <div class="fab" @click="createMode = true">+</div>
    
    <div class="modal" v-if="createMode">
      <div class="popup">
        <div class="popup-title">Neues Projekt</div> 
        <div class="popup-content">
          <input type="text" placeholder="Nummer" v-model="createNumber"/>         
          <input type="text" placeholder="Name" v-model="createName"/> 
        </div>       
        <div class="popup-buttons">
          <div class="popup-button negative" @click="createMode = false">Abbrechen</div> 
          <div class="popup-button" @click="createProject()">OK</div>           
        </div>       
      </div>    
    </div>    
    
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'Projects',
  data () {
    return {
      createMode: false,
      createNumber: '',
      createName: '',
      filter: 'myactive'
    }
  },
  methods: {
    goProject: function (id) {
      // console.log(this.$route.path + '/' + id)
      // this.$router.push('project/' + id)
      this.$router.push({ name: 'Project', params: { id: id } })
    },
    getProjects: function () {
      this.$store.commit('SET_BUSY', true)
      this.$store.dispatch('projects/getAll').then(() => {
        this.$store.commit('SET_BUSY', false)
      })
    },
    createProject: function () {
      let payload = {
        name: this.createName,
        number: this.createNumber
      }
      this.$store.dispatch('projects/create', payload).then((res) => {
        console.log(res)
        let createdId = res.data
        this.$store.dispatch('projects/getAll').then((res) => {
          console.log(res)
          this.$router.push({ name: 'Project', params: { id: createdId } })
        })
      })
      /*
      this.$http.post(
        'https://track.koordinauten.de/api/api.php/projects',
        {
          name: this.createName,
          number: this.createNumber,
          customer_id: 1
        },
        {
          emulateJSON: true
        }
      ).then(response => {
        console.log(response)
        this.$router.push({ name: 'Project', params: { id: response.body } })
      }, response => {
        console.log(response)
        // error callback
      })
      */
    },
    projectStateName: function (project) {
      return _.find(this.projectStates, function (o) { return o.id === project.state }).title
    },
    onImageError: function (event) {
      console.log('error')
      event.target.src = 'https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg'
    },
    isInFilter: function (project) {
      if (this.filter === 'myactive' && project.state < 3) {
        if (_.some(project.project_user, ['user_id', parseInt(window.localStorage.userid)])) {
          return true
        }
      }
      if (this.filter === 'inactive' && project.state >= 3) {
        return true
      }
      if (this.filter === 'allactive' && project.state < 3) {
        return true
      }
      return false
    }
  },
  computed: {  
    filteredProjects () {
      let res = this.projects
      if (this.filter === 'myactive') {
        res = this.projects.filter(o => (o.status === 'OFFERED' || o.status === 'WIP') && o.users.some(u => u._id === this.loggedInUser.userId)) // 
      }
      if (this.filter === 'allactive') {
        res = this.projects.filter(o => (o.status === 'OFFERED' || o.status === 'WIP'))
      }
      if (this.filter === 'inactive') {
        res = this.projects.filter(o => (o.status === 'DONE' || o.status === 'CANCELLED')) // && o.users.some(u => u._id === this.loggedInUser._id)
      }
      return res
    },
    ...mapGetters({
      API: 'API',
      loggedInUser: 'users/loggedInUser',
      users: 'users/users',
      projects: 'projects/projects',
      getStatusName: 'projects/getStatusName',
      inactiveCustomers: 'customers/inactiveCustomers',
    })
  },
  mounted: function () {
    window.scrollTo(0, 0)
    this.getProjects()
  },
  watch: {
    '$route.params.state': function (state) {
      this.filter = state
      console.log(state)
    }
  }
}
</script>

