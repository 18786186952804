<template>
  <div class="page project">
    <div class="header shadow" v-if="project">
      <h1>{{project.name}}</h1>
    </div>
    <div class="content" v-if="project">
      <perfect-scrollbar>
      
        <div class="project-head">
          <div class="project-head-image">
            <!--img class="avatar" :src="'http://192.168.7.80/494_klog_2/public/uploads/' + project.image" @error="onImageError"-->
            <img class="avatar" :src="API.imagePath + 'projects/' + project.image" @error="onImageError" v-on:click="uploadActive = !uploadActive" v-if="project.image">
            <button v-if="!project.image" v-on:click="uploadActive = !uploadActive" >Bild hinzufügen</button>
          </div>
          <div class="project-head-text">
            <div class="project-head-text-col-1">
              <input type="text" v-model="project.number" @blur="updateProject" @keyup.enter="updateProject"/>
            </div>
            <div class="project-head-text-col-2">
              <input type="text" v-model="project.name" @blur="updateProject" @keyup.enter="updateProject"/>

              <select v-model="project.customer" @change="updateProject()">
                <option v-for="item in customers" v-bind:value="item" :key="item._id">
                  {{ item.name }}
                </option>
              </select>            
            </div>
          </div>
          <button class="btn-excel btn-icon btn-small" @click="getCsv()">
            <img src="@/assets/gfx/icon_download.png">
            Excel
          </button>
        </div>
        
        <div class="project-dropzone-outer" v-if="uploadActive">                       
          <dropzone ref="projectDropzone" id="dropzone" 
          :options="dropzoneOptions" 
          @vdropzone-success="dropzoneSuccess"/>
        </div>
        <div class="form-container">
          <div class="input-line">
            <label>Status</label>
              <select v-model="project.status" @change="updateProject()">
                <option v-for="item in projectStates" v-bind:value="item.name" :key="item.name">
                  {{ item.displayName }}
                </option>
              </select>
          </div>
          <div class="input-line">
            <label>Letzte Abrechnung</label>
            <datepicker v-model="editDate" format="d. MMM yyyy" language="de" @selected="onPayDateSelect"></datepicker>
            <!--input type="date" v-model="project.lastPayday" @change="updateProject()" /-->
          </div>      
        </div>
        
        <div class="project-users">                       
          <div v-for="item in users" class="project-user" :class="{inactive: !project.users.some(o => o._id === item._id)}" v-on:click="toggleUser(item)" :key="item._id">            
            <img class="avatar" :src="API.imagePath + 'users/' + item.image">
            <p>{{item.username}}</p>
          </div>
        </div>
        
        <div class="project-time">    
          <div class="project-time-total">    
            <div class="project-time-total-hours">{{totalTime}}<span class="small"> Std.</span></div>           
            <div class="project-time-label small">gesamt</div>    
          </div>
          <div class="project-time-open">    
            <div class="project-time-open-hours">{{totalTimeOpen}}<span class="small"> Std.</span></div>           
            <div class="project-time-label small">offen</div>    
          </div>
          <div class="project-time-billed">    
            <div class="project-time-billed-hours">{{totalTimePaid}}<span class="small"> Std.</span></div>           
            <div class="project-time-label small">abgerechnet</div>    
          </div>        
        </div>
        


        <div class="list-item" v-for="log in worklogs" :class="{ inactive: isLogPaid(log) }" @click="editLog(log)" :key="log._id">         
          <div class="list-item-icon list-item-icon-small"><img :src="API.imagePath + 'users/' + log.user.image" v-if="log.user"></div>
          <div class="list-item-text"> 
            <div class="list-item-text-normal">{{log.workStart | moment('dddd, Do MMMM YYYY') }}</div>
            <!--div class="list-item-text-sub" v-if="log.workStart && log.workEnd"><span class="small">{{log.workStart | moment('HH:mm') }} - {{log.workEnd | moment('HH:mm') }}</span></div-->
            <div class="list-item-text-sub">{{log.comment}}</div>          
          </div>
          <div class="list-item-extra list-item-extra-caption">{{timeString(log.minutes)}}h</div>
        </div>
      
      </perfect-scrollbar>
    </div>
    
    <div class="fab" @click="onAddClicked()">+</div>
    
    <modal-log v-if="editorVisible" :log="selectedLog"></modal-log>
    
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'Project',
  data () {
    return {
      project: null,
      selectedLog: null,
      selectedLogId: 0,
      editLogId: 0,
      editDate: null,
      logComment: '',
      logDate: moment().format(),
      logStartTime: {
        HH: moment().subtract({ hours: 1 }).format('HH'),
        mm: moment().format('mm')
      },
      logEndTime: {
        HH: moment().format('HH'),
        mm: moment().format('mm')
      },
      logHours: 0,
      uploadActive: false,
      dropzoneOptions: {
        url: 'http://localhost:3003/projects/upload',
        thumbnailWidth: 150,
        paramName: 'image',
        maxFilesize: 3.5,
        resizeWidth: 1024,
        params: {
          id: 0,
          type: 'project'
        }
      }
    }
  },
  methods: {
    getProject () {
      console.log('getProject')
      this.$store.commit('SET_BUSY', true)
      this.$store.dispatch('projects/getAll').then(() => {
        this.project = this.projects.find(o => o._id === this.$route.params.id)
        this.editDate = this.project.lastPayday
        console.log(this.project)
        this.$store.dispatch('worklogs/getByProject', this.project._id)
        this.$store.commit('SET_BUSY', false)
      })
    },
    toggleUser (item) {
      if (this.project.users.some(o => o._id === item._id)) {
        this.project.users = this.project.users.filter(o => o._id !== item._id)
      } else {
        this.project.users.push(item)
      }
      console.log(this.project.users)
      this.updateProject()
    },
    updateProject () {
      console.log('updateProject')
      console.log(this.project)
      this.$store.commit('SET_BUSY', true)
      this.$store.dispatch('projects/update', this.project).then(()=> {
        this.getProject()
      })
    },
    getCsv () {
      console.log('getCsv')
      this.$store.commit('SET_BUSY', true)
      this.$store.dispatch('worklogs/getCsvByProject', this.project._id).then((res)=> {
        this.$store.commit('SET_BUSY', false)
        console.log(res)
        window.open(this.API.baseURL + res.data, '_blank')
        /*
        var fileURL = window.URL.createObjectURL(new Blob([res.data]))
        var fileLink = document.createElement('a');
        fileLink.href = fileURL
        fileLink.setAttribute('download', this.project.name.replace(' ', '-') + '.xlsx')
        document.body.appendChild(fileLink)
        fileLink.click()
        */
      })
    },
    onCustomerSelect: function (customer) {
      console.log(customer)
      this.project.customer = customer
      this.updateProject()
    },
    onPayDateSelect (date) {
      console.log('onPayDateSelect')
      console.log(date)
      this.project.lastPayday = date
      this.updateProject()
    },
    editLog: function (log) {
      if (log.user._id === this.loggedInUser.userId) {
        this.selectedLog = log
        this.$store.commit('worklogs/SET_EDITOR_VISIBLE', true)
      }
    },
    isLogPaid: function (log) {
      return moment(this.project.lastPayday).isAfter(log.workEnd)
    },
    onImageError: function (event) {
      console.log('error')
      event.target.src = 'https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg'
    },
    timeString: function (minutes) {
      return parseFloat(minutes / 60).toFixed(2).replace('.', ',')
    },
    dropzoneSuccess: function (file, response) {
      this.uploadActive = false
      this.project.image = response
      this.updateProject()
    },
    onLogClick: function (log) {
      this.selectedLogId = log.id
      this.selectedLog = JSON.parse(JSON.stringify(log))
      console.log(log)
    },
    logIsTimeTracked: function (log) {
      if (moment(log.work_start).format('HH:mm') !== '00:00' && moment(log.work_end).format('HH:mm') !== '00:00') {
        return true
      } else {
        return false
      }
    },
    onAddClicked () {
      this.$store.commit('worklogs/SET_CREATING', true)
      this.$store.commit('worklogs/SET_EDITOR_VISIBLE', true)
    }
  },
  computed: {
    totalTime: function () {
      var time = 0
      _.forEach(this.worklogs, function (value) {
        time += value.minutes
      })
      return this.timeString(time)
    },
    totalTimeOpen: function () {
      var time = 0
      var payDate = moment(this.project.lastPayday)
      _.forEach(this.worklogs, function (value) {
        if (moment(value.workStart).isAfter(payDate) || payDate === '0000-00-00') {
          time += value.minutes
        }
      })
      return this.timeString(time)
    },
    totalTimePaid: function () {
      var payDate = moment(this.project.lastPayday)
      var time = 0
      _.forEach(this.worklogs, function (value) {
        if (moment(value.workStart).isBefore(payDate)) {
          time += value.minutes
        }
      })
      return this.timeString(time)
    },
    paydayDateString: function () {
      return moment(this.project.lastPayday).format('YYYY-MM-DD')
    },
    ...mapGetters({
      API: 'API',
      axiosConfig: 'axiosConfig',
      loggedInUser: 'users/loggedInUser',
      users: 'users/users',
      projects: 'projects/projects',
      projectStates: 'projects/projectStates',
      getStatusName: 'projects/getStatusName',
      customers: 'customers/customers',
      worklogs: 'worklogs/worklogs',
      editorVisible: 'worklogs/editorVisible',
    })
  },
  mounted: function () {
    window.scrollTo(0, 0)
    this.getProject()
  },
  watch: {
    '$route.params.id': function () {
      // this.getProject()
    },
    project: function (val) {
      this.dropzoneOptions.params.id = val._id
      this.dropzoneOptions.url = this.API.baseURL + 'projects/upload'
      this.dropzoneOptions.headers = this.axiosConfig.headers
    }
  }
}
</script>

