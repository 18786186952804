<template>
  <div class="user" v-if="users">
    <div class="header">
      <h1>{{user.userName}}</h1>
    </div>
    <div class="page content"> 
      <perfect-scrollbar>

        <div class="user-head" v-if="selectedMonth == null">
          <div class="user-head-image">
            <!--img class="avatar" :src="'http://192.168.7.80/494_klog_2/public/uploads/' + project.image" @error="onImageError"-->
            <img class="avatar" :src="API.imagePath + 'users/' + user.image" v-on:click="uploadActive = !uploadActive">
          </div>
          <div class="user-head-text">
            <div class="user-head-text-col">
              <input type="text" v-model="user.userName"/>      
              <input type="text" v-model="user.email"/>         
            </div>
            <div>
              <input type="password" v-model="passwordEdit"/>
              <button @click="updatePassword()">Passwort ändern</button>   
            </div>
          </div>
        </div>
        
        <div class="user-dropzone-outer" v-if="uploadActive">                       
          <dropzone ref="userDropzone" id="dropzone" :options="dropzoneOptions" @vdropzone-success="dropzoneSuccess"/>
        </div>


        <!--div v-for="item in worklogs" :key="item._id">
          {{item.project.name}}<br>
          {{item.workStart}}<br>
          {{new Date(item.workStart).getMonth()}}/{{new Date(item.workStart).getFullYear()}}
        </div-->
        
        <div v-if="selectedMonth === null">     
          <div class="list-item" v-for="(lastMonth, index) in lastMonths" @click="monthClicked(index)" :key="'monn-' + index">    
            <div class="list-item-icon list-item-icon-small">
              <img v-if="lastMonth.projectOfTheMonth" :src="API.imagePath + 'projects/' + lastMonth.projectOfTheMonth.image" @error="onImageError">
            </div>
            <div class="list-item-text"> 
              <div class="list-item-text-normal">{{getMonthName(lastMonth.month - 1)}} {{lastMonth.year}}</div>         
              <div class="list-item-text-sub">{{totalTime(lastMonth.worklogs)}}</div>
            </div>
          </div>      
        </div>      
        
        <div v-if="selectedMonth !== null">         
          <div class="list-back-title" @click="selectedMonth = null">{{getMonthName(selectedMonth.month - 1)}} {{selectedMonth.year}}</div>    
          <div class="list-item" v-for="log in selectedLogs" :key="log.id">         
            <div class="list-item-icon list-item-icon-small">
              <img :src="API.imagePath + 'projects/' + log.project.image">
            </div>
            <div class="list-item-text"> 
              <div class="list-item-text-normal">{{log.workStart | moment('dddd, Do MMMM YYYY') }} </div>
              <div class="list-item-text-sub"><span class="small">{{log.minutes}} m</span></div>
              <div class="list-item-text-sub">{{log.project.name }}: {{log.comment }}</div>          
            </div>
            <div class="list-item-extra list-item-extra-caption">{{timeString(log.minutes)}}h</div>
          </div>
        </div>
      
      </perfect-scrollbar>
    </div>
    
    <modal-log v-if="selectedLog != null" @negativeClicked="selectedLog = null" @logUpdated="logUpdated" :log="selectedLog"></modal-log>
    
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'User',
  data () {
    return {
      user: {},
      logs: [],
      uploadActive: false,
      dropzoneOptions: {
        url: 'https://track.koordinauten.de/api_upload/upload.php',
        thumbnailWidth: 150,
        paramName: 'image',
        maxFilesize: 3.5,
        resizeWidth: 1024,
        params: {
          id: 0,
          type: 'user'
        }
      },
      currentMonth: 0,
      currentYear: 2018,
      selectedMonth: null,
      selectedLogs: null,
      selectedLog: null,
      lastMonths: [],
      getLogIndex: 0,
      passwordEdit: ''
    }
  },
  methods: {
    getUser: function () {
      this.user = this.users.find(o => o._id === this.$route.params.id)
    },
    timeString: function (minutes) {
      return parseFloat(minutes / 60).toFixed(2).replace('.', ',')
    },
    updateUser: function () {
      this.$store.commit('SET_BUSY', true)
      this.$store.dispatch('users/update', this.user).then(()=> {
        this.$store.commit('SET_BUSY', false)
      })
    },
    updatePassword: function () {
      this.$store.commit('SET_BUSY', true)
      let payload = {
        _id: this.user._id,
        password: this.passwordEdit
      }
      this.$store.dispatch('users/updatePassword', payload).then(()=> {
        this.$store.commit('SET_BUSY', false)
      })
    },
    getLogs: function () {
      this.$store.commit('SET_BUSY', true)
      this.$store.dispatch('worklogs/getByUser', this.user._id).then(() => {
        this.getLastMonthsData()
        this.$store.commit('SET_BUSY', false)
      })
    },
    logUpdated: function () {
      this.selectedMonth = null
      this.selectedLogs = null
      this.selectedLog = null
      this.getLogIndex = 0
      this.getLogs()
    },
    getLastMonthsData: function () {
      this.lastMonths = []
      this.currentYear = moment().format('YYYY')
      this.currentMonth = moment().format('MM')
      var yearSwitch = false
      for (var i = 0; i < 12; i++) {
        if (this.currentMonth - i <= 0) yearSwitch = true
        let month = this.currentMonth - i > 0 ? this.currentMonth - i : this.currentMonth - i + 12
        let year = yearSwitch ? this.currentYear - 1 : this.currentYear
        let worklogs = this.worklogs.filter(o => new Date(o.workStart).getMonth() == (month - 1) && new Date(o.workStart).getFullYear() == year)
        let projectOfTheMonth
        let projectTimes = []
        this.projects.forEach(project => {
          let projectTime = {
            project: project,
            time: 0
          }
          worklogs.forEach(worklog => {
            if (worklog.project._id === project._id) {
              projectTime.time += worklog.minutes
            }
          })
          projectTimes.push(projectTime)
        })
        projectTimes.sort(function (a, b) {
          return b.time - a.time
        })
        projectOfTheMonth = projectTimes[0].time > 0 ? projectTimes[0].project : null
        var monthData = {
          month: month,
          year: year,
          worklogs: worklogs,
          projectOfTheMonth: projectOfTheMonth,
        }
        this.lastMonths.push(monthData)
      }
    },
    getMonthName: function (monthIndex) {
      return moment.months(monthIndex)
    },
    dropzoneSuccess: function (file, response) {
      console.log(response)
      this.uploadActive = false
      this.user.image = response
      this.updateUser()
    },
    totalTime: function (logs) {
      var time = 0
      _.forEach(logs, function (log) {
        time += log.minutes
      })
      return this.timeString(time)
    },
    monthClicked: function (index) {
      // this.selectedLogs = this.lastLogs[index]
      this.selectedMonth = this.lastMonths[index]
      this.selectedLogs = this.selectedMonth.worklogs
      console.log('monthClicked')
      console.log(this.selectedMonth)
    },
    onImageError: function (event) {
      event.target.src = 'https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg'
    }
  },
  computed: {
    totalTimeInMonth: function () {
      var times = new Array(12)
      for (var i = 0; i < 12; i++) {
        times[i] = this.totalTime(this.lastLogs[i])
      }
      return times
    },
    ...mapGetters({
      API: 'API',
      axiosConfig: 'axiosConfig',
      users: 'users/users',
      projects: 'projects/projects',
      projectStates: 'projects/projectStates',
      getStatusName: 'projects/getStatusName',
      customers: 'customers/customers',
      worklogs: 'worklogs/worklogs',
      editorVisible: 'worklogs/editorVisible',
    })
  },
  mounted: function () {
    this.getUser()
    this.getLogs()
    console.log(this.lastMonths)
  },
  watch: {
    '$route.params.id': function () {
      this.getUser()
      this.getLogs()
    },
    user: function (val) {
      this.dropzoneOptions.params.id = val._id
      this.dropzoneOptions.url = this.API.baseURL + 'user/upload'
      this.dropzoneOptions.headers = this.axiosConfig.headers
    }
  }
}
</script>

