import Vue from 'vue'
import Vuex from 'vuex'
import users from './modules/users'
import projects from './modules/projects'
import customers from './modules/customers'
import worklogs from './modules/worklogs'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    API: {
      // baseURL: process.env.VUE_APP_API_BASEURL,
      // imagePath: process.env.VUE_APP_API_BASEURL + 'images/',
      // baseURL: 'http://localhost:3004/',
      // imagePath: 'http://localhost:3004/images/',
      // baseURL: 'http://localhost:3004/',
      // imagePath: 'http://localhost:3004/images/',
      baseURL: 'https://ktracker-api.koordinauten.de/',
      imagePath: 'https://ktracker-api.koordinauten.de/images/',
    },
    busy: false,
    axiosConfig: {}
  },
  getters: {
    API: state => state.API,
    axiosConfig: state => state.axiosConfig,
    busy: state => state.busy
  },
  mutations: {
    SET_TOKEN (state, data) {
      console.log('SET_TOKEN')
      console.log(data)
      state.axiosConfig = {
        headers: {'Authorization': 'Bearer ' + data}
      }
      console.log(state.axiosConfig)
    },
    SET_BUSY (state, data) {
      state.busy = data
    }
  },
  actions: {
  },
  modules: {
    users,
    projects,
    customers,
    worklogs
  }
})
