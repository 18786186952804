<template>
  <div class="work">
    <div class="header">
      <h1>{{user.nickname}}</h1>
    </div>
    <div class="content" v-if="days.length > 0">
      
      <p>{{logs.length}}</p>
      
      <div  v-for="(day, index) in days" :key="'day' + index">     
        <h3>{{day.format('dddd, DD.MMMM YYYY')}} </h3>
        <div class="logtable-item" v-for="log in logs[index]" @click="selectedLog = log" :key="log.id">         
          <div class="logtable-item-cell">         
            <div class="list-item-icon list-item-icon-small"><img :src="'https://track.koordinauten.de/api_upload/projectimg/' + log.projects[0].image"></div>
            <select v-model="log.projects[0].id">
              <option v-for="project in projects" v-bind:value="project.id" :key="project.id">
                {{ project.name }}
              </option>
            </select>  
          </div>
          <div class="logtable-item-cell">         
            <k-time-picker-moment :time.sync="log.work_start" @change="logEdited(log)"></k-time-picker-moment>
          </div>
          <div class="logtable-item-cell">         
            <k-time-picker-moment :time="log.work_end"></k-time-picker-moment>
          </div>
          <div class="logtable-item-cell">         
            <input type="text" v-model="log.comment"/>
          </div>
          <div class="list-item-extra list-item-extra-caption">{{timeString(log.minutes)}}h</div>
        </div>
      </div>
      
    </div>
    
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'Work',
  data () {
    return {
      user: {
        id: '',
        nickname: '',
        email: ''
      },
      days: [],
      getLogIndex: 0,
      logs: [],
      projects: [],
      selectedLog: null
    }
  },
  methods: {
    timeString: function (minutes) {
      return parseFloat(minutes / 60).toFixed(2).replace('.', ',')
    },
    getLogs: function () {
      var timeSearchString = this.days[this.getLogIndex].format('YYYY-MM-DD')
      console.log('https://track.koordinauten.de/api/api.php/worklogs?transform=1&include=projects&order=work_start,desc&filter[]=user_id,eq,' + this.user.id + '&filter[]=work_start,sw,' + timeSearchString)
      this.$http.get('https://track.koordinauten.de/api/api.php/worklogs?transform=1&include=projects&order=work_start,desc&filter[]=user_id,eq,' + this.user.id + '&filter[]=work_start,sw,' + timeSearchString).then(response => {
        // success callback
        // reactive caveat
        if (this.logs.length < 10) {
          this.logs.push(response.body.worklogs)
        }
        console.log(this.logs[this.getLogIndex])

        this.getLogIndex++
        if (this.getLogIndex < 10) {
          this.getLogs()
        } else {
          this.getLogIndex = 0
        }
      }, response => {
        // error callback
        console.log(response)
      })
    },
    logEdited: function (log) {
      console.log(log)
    },
    logUpdated: function () {
      this.selectedMonth = null
      this.selectedLogs = null
      this.selectedLog = null
      this.getLogIndex = 0
      this.getLogs()
    },
    createDays: function () {
      for (var i = 0; i < 10; i++) {
        this.days[i] = moment().subtract(i, 'days')
        // console.log(this.days[i].format('DD'))
      }
      this.getProjects()
      this.getLogs()
    },
    totalTime: function (logs) {
      var time = 0
      _.forEach(logs, function (log) {
        time += log.minutes
      })
      return this.timeString(time)
    },
    onImageError: function (event) {
      console.log('error')
      event.target.src = 'https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg'
    },
    getProjects: function () {
      this.$http.get('https://track.koordinauten.de/api/api.php/projects?transform=1&include=project_user,customers&order=number,desc').then(response => {
        // success callback
        this.projects = response.body.projects
        console.log(this.projects)
      }, response => {
        console.log(response)
        // error callback
      })
    }
  },
  created: function () {
    this.user.nickname = window.localStorage.usernick
    this.user.name = window.localStorage.username
    this.user.image = window.localStorage.userimage
    this.user.id = window.localStorage.userid

    console.log(moment())
    this.createDays()
  },
  watch: {
    '$route.params.id': function () {
      this.getUser()
    },
    user: function (val) {
      this.dropzoneOptions.params.id = val.id
    }
  }
}
</script>

